/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useEtaTranslations } from "shared/hooks/useEtaTranslations";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";

import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { VinMilestoneCell } from "components/organisms/base-table/Cell/VinMilestoneCell";
import { MadChicletCSS as MadChiclet } from "components/chiclets";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { getCurrentPositionDetails } from "shared/utils/entity.utils";
import { SourceType } from "api/consts";
import Colors from "styles/colors";

import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import VinViewEntityDetailsState from "../redux/VinViewEntityDetailsState";
import { ShippabilityStatusText } from "shared/components/molecules/ShippabilityStatusText.molecule";
import { translateExceptionName } from "pages/finishedvehicle/utils/exceptions.utils";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import { FeaturedReference } from "shared/components/molecules/FeaturedReference.molecule";

// Helpers
const findReference = (references, key) => {
  if (references === undefined) {
    return null;
  }
  const ref = references.find((obj) => obj.qualifier === key);
  return ref ? ref.value : null;
};

const VinCell = (props) => {
  const {
    id,
    description,
    references,
    commentsCount,
    lifeCycleState,
    isShippable,
    displayShippabilityStatus,
    featuredReferences,
  } = props.value;
  const { t } = useTranslation("vinview-search");
  const orderType = findReference(references, "OrderType") || "";
  const orderNumber = findReference(references, "OrderNumber") || "";
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {commentsCount && commentsCount > 0 ? (
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>{t("vinview-search:This VIN contains comments")}</Text>
            }
          >
            <Icon
              src={faCommentAlt}
              color={Colors.comments.unreadCommentIcon}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        ) : null}
        <Text bold>{lifeCycleState === "Prebuilt" ? t("TBD") : id}</Text>
      </div>
      {description && description !== "None" ? (
        <Text>{description}</Text>
      ) : null}
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderType ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("vinview-search:Order Type")} -
            </Text>
            <Text>{orderType}</Text>
          </React.Fragment>
        ) : null}
      </div>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderNumber ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("vinview-search:Order #")} -
            </Text>
            <Text>{orderNumber}</Text>
          </React.Fragment>
        ) : null}
      </div>
      {featuredReferences && featuredReferences.length > 0 && (
        <FeaturedReference featuredReferences={featuredReferences} />
      )}

      {displayShippabilityStatus ? (
        <ShippabilityStatusText
          isShippable={isShippable}
        ></ShippabilityStatusText>
      ) : null}
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    references: PropTypes.array,
    commentsCount: PropTypes.number,
    lifeCycleState: PropTypes.string,
    isShippable: PropTypes.bool,
    displayShippabilityStatus: PropTypes.bool,
    featuredReferences: PropTypes.array,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LastUpdateCell = (props) => {
  const {
    mode,
    lastPosition,
    lastProgress,
    currentLocation,
    canDisplayConnectedCarSource,
  } = props.value;

  const { t } = useTranslation("vinview-search");

  let isOnShipment = false;

  if (lastPosition?.currentPositionType !== t("vinview-search:At Location")) {
    isOnShipment = true;
  }

  let city = null;
  let state = null;

  if (lastPosition) {
    city = lastPosition?.city?.trim();
    state = lastPosition?.state?.trim();
  }

  let cityAndState = null;
  if (city || state) {
    if (city && !state) {
      cityAndState = city;
    } else if (state && !city) {
      cityAndState = state;
    } else {
      cityAndState = `${city}, ${state}`;
    }
  }

  let modeType = mode;
  if (lastPosition?.currentPositionType === t("vinview-search:On Rail")) {
    modeType = "Rail";
  } else if (
    lastPosition?.currentPositionType === t("vinview-search:On the Road")
  ) {
    modeType = "Truck";
  } else if (
    lastPosition?.currentPositionType === t("vinview-search:On the Water")
  ) {
    modeType = "Ocean";
  }

  let source = lastPosition?.sourceType;
  if (source === SourceType.SHIPMENT) {
    source = t("vinview-search:Shipment");
  } else if (source === SourceType.CONNECTED_CAR || source === SourceType.TAG) {
    source = t("vinview-search:Connected Car");
  } else if (source === SourceType.MILESTONE) {
    source = t("vinview-search:Milestone");
  }

  // H2-3038: on VinView suppress source field per customer configuration
  const canDisplaySource =
    source &&
    (lastPosition?.sourceType !== SourceType.CONNECTED_CAR ||
      canDisplayConnectedCarSource);

  if (
    isOnShipment &&
    (lastProgress?.eventTs || cityAndState || lastPosition?.sourceType)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div className="d-flex align-items-center flex-wrap">
          {modeType ? (
            <Tooltip
              placement="top"
              tooltipChildren={<Text>{modeType}</Text>}
              style={{ marginRight: "0.5em" }}
            >
              <MadChiclet
                shipmentMode={modeType}
                stopMode={modeType}
                activeException={null}
                hideBorder={true}
                height={24}
                width={24}
              />
            </Tooltip>
          ) : null}
          <Text>
            {lastPosition?.currentPositionType ??
              t("vinview-search:On Shipment")}
          </Text>
        </div>

        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            <Text>
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}

        {/* City/State */}
        {cityAndState ? <Text>{cityAndState}</Text> : null}

        {/* Departing Location */}
        {source === t("vinview-search:Shipment") ||
        source === t("vinview-search:Milestone") ? (
          <React.Fragment>
            {lastPosition?.currentPositionName ? (
              <Text>{lastPosition?.currentPositionName}</Text>
            ) : null}
          </React.Fragment>
        ) : null}
        {/* Source */}
        {canDisplaySource ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("vinview-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
      </div>
    );
  } else if (
    !isOnShipment &&
    (lastPosition?.datetime || currentLocation?.name || cityAndState)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <Text>{t("vinview-search:At Location")}</Text>
        {/* Location Name */}
        {lastPosition?.currentPositionName ? (
          <Text>{lastPosition?.currentPositionName}</Text>
        ) : null}
        {/* City/State */}
        {cityAndState ? <Text>{cityAndState}</Text> : null}
        {/* Source */}
        {canDisplaySource ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("vinview-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
        {/* Arrival Time */}
        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            {/* <Text>Departed At:</Text> */}
            <Text>
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

LastUpdateCell.propTypes = {
  value: PropTypes.shape({
    lastPosition: PropTypes.object,
    lastProgress: PropTypes.object,
    state: PropTypes.string,
    mode: PropTypes.string,
    currentLocation: PropTypes.object,
    canDisplayConnectedCarSource: PropTypes.bool,
  }).isRequired,
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */

const LocationCell = (props) => {
  const {
    location,
    type,
    eta,
    tripPlanCompleteTs,
    etaWindow = null,
    manualEtaRangeStart = null,
    manualEtaRangeEnd = null,
    completedDate = null,
  } = props.value;
  const { t } = useTranslation("vinview-search");
  const { getEtaTranslation, isEtaName } = useEtaTranslations();

  if (_.isNil(location)) {
    return <div />;
  }

  const isPickUpOr = type === "origin" ? t("Pickup") : t("Delivery");

  const timeToDisplay = location.arrived
    ? location.arrived
    : tripPlanCompleteTs;

  let etaElement = null;

  if (isEtaName(eta)) {
    etaElement = (
      <Text>
        {getEtaTranslation(eta, manualEtaRangeStart, manualEtaRangeEnd)}
      </Text>
    );
  } else if (etaWindow) {
    const parsedEta = JSON.parse(etaWindow);
    etaElement = `${localizedDateFormatter(parsedEta[0])} -
        ${localizedDateFormatter(parsedEta[1])}`;
  } else {
    etaElement = <DateTime dateTime={eta} plain localize />;
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text bold>{location.code}</Text>
      <Text>{location.name}</Text>
      {location.city && location.state ? (
        <Text>{`${location.city}, ${location.state}`}</Text>
      ) : null}
      {timeToDisplay ? (
        <React.Fragment>
          <Text bold underline>
            {t("Actual")} {isPickUpOr}
          </Text>
          <DateTime plain localize dateTime={timeToDisplay} />
        </React.Fragment>
      ) : null}
      {!timeToDisplay && eta ? (
        <div css={{ display: "flex", flexDirection: "row" }}>
          <Text
            bold
            style={{
              marginRight: 5,
            }}
          >
            {t("ETA")}:
          </Text>
          {etaElement}
        </div>
      ) : null}
      {completedDate ? (
        <React.Fragment>
          <Text bold underline>
            {t("Complete Date")}
          </Text>
          <DateTime plain localize dateTime={completedDate} />
        </React.Fragment>
      ) : null}
    </div>
  );
};

LocationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

const ExceptionCell = (props) => {
  const { exceptions, categories, showItssData } = props.value;
  const { t } = useTranslation(["fv-vin-search", "exceptions"]);

  const usualExceptions = exceptions?.filter(
    (exception) =>
      exception.reasonCode !== "ITSS" && exception.reasonCode !== "SB",
  );

  let itssIds = [];
  let spotBuyCodes = [];
  if (categories?.length > 0) {
    itssIds = categories
      .filter((category) => category.group === "In Transit Stop Ship")
      .map((item) => item.category);
    spotBuyCodes = categories
      .filter((category) => category.group === "Spot Buy")
      .map((item) => item.category);
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {_.uniqBy(usualExceptions, "name")?.map((exception, index) => (
        <Text bold key={index}>
          {translateExceptionName(exception.name, t)}
        </Text>
      ))}
      {/* ITSS IDs */}
      {showItssData && itssIds.length ? (
        <React.Fragment>
          <Text bold>{t("fv-vin-search:In Transit Stop Ship")}</Text>
          <div className="text-nowrap ms-2">
            <ShowMoreList
              title={t("fv-vin-search:In Transit Stop Ship IDs")}
              list={itssIds}
              visibleItemCount={4}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

ExceptionCell.propTypes = {
  value: PropTypes.shape({
    exceptions: PropTypes.array,
    categories: PropTypes.array,
    showItssData: PropTypes.bool,
  }),
};

export const columns = (t, selectedDealerOrgId, showItssData) => {
  const results = [
    {
      Header: t("vinview-search:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dispatch = useDispatch();
        const vin = cellInfo.row.original.id;
        const internalId = cellInfo.row.original.internal_id;
        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        const [watch, setWatch] = useState(watched);
        return (
          <WatchToggle
            key={vin}
            checked={watch ?? false}
            onChange={(newWatchValue) => {
              dispatch(
                VinViewEntityDetailsState.actionCreators.setWatchEntity(
                  null, // setWatchEntity needs solutionId as first parameter until VinViewDetails is fully completed.
                  internalId,
                  newWatchValue,
                  selectedDealerOrgId,
                ),
              );
              setWatch(newWatchValue);
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("vinview-search:VIN"),
      id: "vin",
      Cell: VinCell,
      accessor: (d) => {
        return {
          id: d.id,
          description: d.description,
          references: d.references,
          commentsCount: d.commentsCount,
          lifeCycleState: d.lifeCycleState,
          isShippable: d.isShippable,
          displayShippabilityStatus:
            d.configurations?.displayShippabilityStatus,
          featuredReferences: d.featuredReferences,
        };
      },
      minWidth: 180,
    },
    {
      Header: t("vinview-search:Last Milestone"),
      id: "lastMilestone",
      minWidth: 125,
      Cell: (cellInfo) => {
        return (
          <VinMilestoneCell
            lastMilestone={cellInfo.row.original.lastStatusUpdate}
          />
        );
      },
    },
    {
      Header: t("vinview-search:Last Update"),
      id: "activeShipment",
      minWidth: 125,
      Cell: LastUpdateCell,
      accessor: (details) => {
        return {
          mode: details.activeTransportMode,
          lastPosition: getCurrentPositionDetails(details, t),
          lastProgress: details.lastProgressUpdate,
          currentLocation: details.locationData?.current,
          canDisplayConnectedCarSource:
            details?.configurations?.displayConnectedCarSource,
        };
      },
    },
    {
      Header: t("vinview-search:Origin Details"),
      id: "origin",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        return {
          location: d.ultimateOrigin,
          type: "origin",
        };
      },
    },
    {
      Header: t("vinview-search:Destination Details"),
      id: "destinationTime",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        let eta = null;
        // If the VIN is not delivered, show the ETA.
        if (d?.lifeCycleState !== "Delivered") {
          // `lastEntityProgressUpdate.destinationEta` is the source of the ETA for VINView.
          // The API will handle which ETA to show (FV, HERE, Smart, etc) in this field.
          eta = d?.lastEntityProgressUpdate?.destinationEta;
        }

        return {
          location: d.ultimateDestination,
          type: "destination",
          eta: eta,
          etaWindow:
            d?.lastEntityProgressUpdate?.destinationEtaWindow &&
            d?.lastEntityProgressUpdate?.destinationEta !==
              d?.lastEntityProgressUpdate?.destinationEtaEnd
              ? d?.lastEntityProgressUpdate?.destinationEtaWindow
              : null,
          t: t,
          tripPlanCompleteTs: d?.tripPlanCompleteTs,
          manualEtaRangeStart: d?.manualEtaRangeStart,
          manualEtaRangeEnd: d?.manualEtaRangeEnd,
          completedDate: d?.completedDate,
        };
      },
    },
    {
      Header: t("vinview-search:Active Exceptions"),
      id: "shipmentExeptions",
      disableSortBy: true,
      minWidth: 125,
      Cell: ExceptionCell,
      accessor: (d) => {
        return {
          references: d.references,
          exceptions: d.exceptions,
          categories: d.categories,
          showItssData: showItssData,
          t: t,
        };
      },
    },
  ];

  return results;
};
