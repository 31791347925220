/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";

import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";

import { VinMilestoneCell } from "components/organisms/base-table/Cell/VinMilestoneCell";
import { MadChicletCSS as MadChiclet } from "components/chiclets";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { getCurrentPositionDetails } from "shared/utils/entity.utils";
import { SourceType } from "api/consts";
import Colors from "styles/colors";
import { FeaturedReference } from "shared/components/molecules/FeaturedReference.molecule";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import CarrierViewEntityDetailsState from "../redux/CarrierViewEntityDetailsState";

// Helpers
const findReference = (references, key) => {
  if (references === undefined) {
    return null;
  }
  const ref = references.find((obj) => obj.qualifier === key);
  return ref ? ref.value : null;
};

const VinCell = (props) => {
  const { id, description, references, commentsCount, featuredReferences } =
    props.value;
  const { t } = useTranslation("carrierview-search");
  const orderType = findReference(references, "OrderType") || "";
  const orderNumber = findReference(references, "OrderNumber") || "";
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {commentsCount && commentsCount > 0 ? (
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>{t("carrierview-search:This VIN contains comments")}</Text>
            }
          >
            <Icon
              src={faCommentAlt}
              color={Colors.comments.unreadCommentIcon}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        ) : null}
        <Text bold>{id}</Text>
      </div>
      {description && description !== "None" ? (
        <Text>{description}</Text>
      ) : null}
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderType ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("carrierview-search:Order Type")} -
            </Text>
            <Text>{orderType}</Text>
          </React.Fragment>
        ) : null}
      </div>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderNumber ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("carrierview-search:Order #")} -
            </Text>
            <Text>{orderNumber}</Text>
          </React.Fragment>
        ) : null}
      </div>

      {featuredReferences && featuredReferences.length > 0 && (
        <FeaturedReference featuredReferences={featuredReferences} />
      )}
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    references: PropTypes.array,
    commentsCount: PropTypes.number,
    featuredReferences: PropTypes.array,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LastUpdateCell = (props) => {
  const {
    mode,
    lastPosition,
    lastProgress,
    currentLocation,
    canDisplayConnectedCarSource,
    t,
  } = props.value;

  let isOnShipment = false;

  if (lastPosition?.currentPositionType !== t("At Location")) {
    isOnShipment = true;
  }

  let city = null;
  let state = null;

  if (lastPosition) {
    city = lastPosition?.city?.trim();
    state = lastPosition?.state?.trim();
  }

  let cityAndState = null;
  if (city || state) {
    if (city && !state) {
      cityAndState = city;
    } else if (state && !city) {
      cityAndState = state;
    } else {
      cityAndState = `${city}, ${state}`;
    }
  }

  let modeType = mode;
  if (lastPosition?.currentPositionType === t("On Rail")) {
    modeType = "Rail";
  } else if (lastPosition?.currentPositionType === t("On Road")) {
    modeType = "Truck";
  } else if (lastPosition?.currentPositionType === t("On Water")) {
    modeType = "Ocean";
  }

  let source = lastPosition?.sourceType;
  if (source === SourceType.SHIPMENT) {
    source = t("Shipment");
  } else if (source === SourceType.CONNECTED_CAR || source === SourceType.TAG) {
    source = t("Connected Car");
  } else if (source === SourceType.MILESTONE) {
    source = t("Milestone");
  }

  // H2-3038: on VinView suppress source field per customer configuration
  const canDisplaySource =
    source &&
    (lastPosition?.sourceType !== SourceType.CONNECTED_CAR ||
      canDisplayConnectedCarSource);

  if (
    isOnShipment &&
    (lastProgress?.eventTs || cityAndState || lastPosition?.sourceType)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div className="d-flex align-items-center flex-wrap">
          {modeType ? (
            <Tooltip
              placement="top"
              tooltipChildren={<Text>{modeType}</Text>}
              style={{ marginRight: "0.5em" }}
            >
              <MadChiclet
                shipmentMode={modeType}
                stopMode={modeType}
                activeException={null}
                hideBorder={true}
                height={24}
                width={24}
              />
            </Tooltip>
          ) : null}
          <Text>{lastPosition?.currentPositionType ?? t("On Shipment")}</Text>
        </div>

        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            <Text>
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}

        {/* City/State */}
        {cityAndState ? <Text>{cityAndState}</Text> : null}

        {/* Departing Location */}
        {source === t("Shipment") || source === t("Milestone") ? (
          <React.Fragment>
            {lastPosition?.currentPositionName ? (
              <Text>{lastPosition?.currentPositionName}</Text>
            ) : null}
          </React.Fragment>
        ) : null}
        {/* Source */}
        {canDisplaySource ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
      </div>
    );
  } else if (
    !isOnShipment &&
    (lastPosition?.datetime || currentLocation?.name || cityAndState)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <Text>{t("carrierview:At Location")}</Text>
        {/* Location Name */}
        {lastPosition?.currentPositionName ? (
          <Text>{lastPosition?.currentPositionName}</Text>
        ) : null}
        {/* City/State */}
        {cityAndState ? <Text>{cityAndState}</Text> : null}
        {/* Source */}
        {canDisplaySource ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
        {/* Arrival Time */}
        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            {/* <Text>Departed At:</Text> */}
            <Text>
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

LastUpdateCell.propTypes = {
  value: PropTypes.shape({
    lastPosition: PropTypes.object,
    lastProgress: PropTypes.object,
    state: PropTypes.string,
    mode: PropTypes.string,
    currentLocation: PropTypes.object,
    canDisplayConnectedCarSource: PropTypes.bool,
    t: PropTypes.func,
  }).isRequired,
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */

const LocationCell = (props) => {
  const { location, type, tripPlanCompleteTs } = props.value;
  const { t } = useTranslation("carrierview-search");

  if (_.isNil(location)) {
    return <div />;
  }

  const isPickUpOr = type === "origin" ? t("Pickup") : t("Delivery");

  const timeToDisplay = location.arrived
    ? location.arrived
    : tripPlanCompleteTs;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text bold>{location.code}</Text>
      <Text>{location.name}</Text>
      {location.city && location.state ? (
        <Text>{`${location.city}, ${location.state}`}</Text>
      ) : null}
      {timeToDisplay ? (
        <React.Fragment>
          <Text bold underline>
            {t("Actual")} {isPickUpOr}
          </Text>
          <DateTime plain localize dateTime={timeToDisplay} />
        </React.Fragment>
      ) : null}
    </div>
  );
};

LocationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

export const columns = (t) => {
  const results = [
    {
      Header: t("carrierview-search:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dispatch = useDispatch();

        const vin = cellInfo.row.original.id;
        const internalId = cellInfo.row.original.internal_id;
        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        const [watch, setWatch] = useState(watched);
        return (
          <WatchToggle
            key={vin}
            checked={watch ?? false}
            onChange={(newWatchValue) => {
              dispatch(
                CarrierViewEntityDetailsState.actionCreators.setWatchEntity(
                  null, // setWatchEntity needs solutionId as first parameter until VinViewDetails is fully completed.
                  internalId,
                  newWatchValue,
                ),
              );
              setWatch(newWatchValue);
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("carrierview-search:VIN"),
      id: "vin",
      Cell: VinCell,
      accessor: (d) => {
        return {
          id: d.id,
          description: d.description,
          references: d.references,
          commentsCount: d.commentsCount,
          featuredReferences: d.featuredReferences,
        };
      },
      minWidth: 180,
    },
    {
      Header: t("carrierview-search:Last Milestone"),
      id: "lastMilestone",
      minWidth: 125,
      Cell: (cellInfo) => {
        return (
          <VinMilestoneCell
            lastMilestone={cellInfo.row.original.lastStatusUpdate}
          />
        );
      },
    },
    {
      Header: t("carrierview-search:Last Update"),
      id: "activeShipment",
      minWidth: 125,
      Cell: LastUpdateCell,
      accessor: (details) => {
        return {
          mode: details.activeTransportMode,
          lastPosition: getCurrentPositionDetails(details, t),
          lastProgress: details.lastProgressUpdate,
          currentLocation: details.locationData?.current,
          canDisplayConnectedCarSource:
            details?.configurations?.displayConnectedCarSource,
          t: t,
        };
      },
    },
    {
      Header: t("carrierview-search:Origin Details"),
      id: "origin",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        return {
          location: d.ultimateOrigin,
          type: "origin",
        };
      },
    },
    {
      Header: t("carrierview-search:Destination Details"),
      id: "destinationTime",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        return {
          location: d.ultimateDestination,
          type: "destination",
          t: t,
          tripPlanCompleteTs: d?.tripPlanCompleteTs,
        };
      },
    },
  ];

  return results;
};
